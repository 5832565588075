<template>
  <!-- 详细信息表单展示 -->
  <div class="base-form" :style="{ width: width }">
    <el-form
      v-loading="formData.length"
      :status-icon="statusIcon"
      ref="elForm"
      :label-position="labelPosition"
      :model="formData"
      class="clearfix form-flex"
      :rules="rules"
      :validate-on-rule-change="false"
      :disabled="disabled"
    >
      <div
        :class="isShowLine(item)"
        :style="{
          width:
            item.type === 'groupTitle' || item.type === 'customPage'
              ? '100%'
              : item.colNum === 1
              ? `calc(${oneColumnWidth} - 10px)`
              : `calc(${twoColumnWidth} - 10px)`,
        }"
        v-for="item in formAttr"
        v-if="formData && !item.hidden && item.groupShow"
        :key="item.prop"
        :devid="item.fieldId ? item.fieldId : item.selectionId"
      >
        <!-- devid:方便实施通过divid获取dom -->
        <!-- 部分标题 -->
        <p
          class="groupTitle"
          v-if="item.type === 'groupTitle' && !item.hidden"
          @click="toggleGroup(item)"
        >
          <i
            :class="
              hideSelectionId.indexOf(item.selectionId) !== -1
                ? 'el-icon-arrow-down rotate90'
                : 'el-icon-arrow-down'
            "
          ></i>
          <span v-html="item.title"></span>
        </p>
        <!-- 无数据占位 -->
        <p
          class="noDataLine"
          v-else-if="
            (item.type === 'noData' || item.hidden) &&
            hideSelectionId.indexOf(item.selectionId) === -1
          "
        ></p>
        <!--        新版自定义页面内嵌-->
        <div
          v-else-if="item.type === 'customPage'"
          style="width: 100%; overflow: auto; max-height: 500px"
          :style="{
            minHeight:
              hideSelectionId.indexOf(item.selectionId) === -1 ? '50px' : '0',
          }"
        >
          <CCRenderer
            v-show="hideSelectionId.indexOf(item.selectionId) === -1"
            :componentData="item.componentData"
            :canvasStyleData="item.canvasStyleData"
          />
        </div>
        <el-form-item
          v-else
          v-show="hideSelectionId.indexOf(item.selectionId) === -1"
          :prop="item.prop"
          :class="[
            item.className,
            { 'no-label': item.noLabel, remind: item.remind, itemBox: true },
          ]"
          :label-width="
            item.labelWidth ? item.labelWidth + 'px' : labelWidth + 'px'
          "
        >
          <!-- 自定义表单label -->
          <span slot="label">
            <span class="span-box">
              <span>{{ item.label }}</span>
              <!-- <span>-----{{item.prop}}</span> -->
              <!-- 订单和发票对象地址字段逻辑更改 -->
              <span
                v-if="
                  (objectApi == 'cloudccorder' || objectApi == 'Invoices') &&
                  item.edit &&
                  item.fieldType === 'AD'
                "
                class="cursor-pointer allow-click"
                style="margin-left: 8px"
                @click="changeAddress(item)"
              >
                [{{ $t("label.change") }}]
              </span>
              <el-tooltip
                popper-class="my-tooltip"
                class="item"
                effect="dark"
                v-show="item.helpText"
                placement="top-start"
              >
                <div slot="content" v-html="helpText(item.helpText)"></div>
                <svg
                  class="icon helpImg"
                  aria-hidden="true"
                  style="display: inline-block"
                >
                  <use href="#icon-bangzhu-changtai"></use>
                </svg>
              </el-tooltip>
              <svg
                class="icon"
                aria-hidden="true"
                style="
                  display: inline-block;
                  width: 18px;
                  height: 18px;
                  cursor: pointer;
                  margin-left: 5px;
                  margin-top: -7px;
                "
                v-if="
                  isEdit &&
                  ((objectApi === 'Account' &&
                    isNotPersonaccount &&
                    (item.prop === 'name' || item.prop === 'parent')) ||
                    (objectApi === 'Contact' &&
                      isNotPersonaccount &&
                      (item.prop === 'name' || item.prop === 'zsss')))
                "
                @contextmenu.prevent.stop="routers"
                @click.prevent.stop="routers"
              >
                <use href="#icon-cengji"></use>
              </svg>
            </span>
          </span>
          <!-- 创建人、最后修改人 -->
          <span
            class="infoItem"
            v-if="
              item.apiname === 'createbyid' || item.apiname === 'lastmodifybyid'
            "
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
          >
            <span>
              {{ optionList[item.apiname][0]["label"] }}
            </span>
          </span>
          <!-- 查找/主详字段点击可跳转到对应对象记录的详情页 -->
          <span
            class="infoItem"
            v-else-if="item.type === 'remote-select'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
          >
            <!-- 所有人 特殊处理 -->
            <a
              class="cursor-pointer allow-click"
              v-if="
                item.lookupObj &&
                item.lookupObj === '005' &&
                formData[item.prop] &&
                formData[item.prop].slice(0, 3) === '005'
              "
              :href="hrefUrl(item)"
              @click="detail($event, item)"
            >
              {{ formData1[item.prop] }}
            </a>
            <span
              class="cursor-pointer allow-click Unallow-click"
              v-else-if="
                item.lookupObj &&
                item.lookupObj === '005' &&
                formData[item.prop] &&
                formData[item.prop].slice(0, 3) !== '005'
              "
            >
              {{ formData1[item.prop] }}
            </span>
            <!-- 其他可跳转字段 -->
            <a
              class="cursor-pointer allow-click"
              v-else-if="
                item.apiname !== 'profile' &&
                item.apiname !== 'role' &&
                item.apiname !== 'marketsea'
              "
              :href="hrefUrl(item)"
              @click="detail($event, item)"
            >
              <span>
                <Popover-cpn
                  :recordObj="item"
                  :content="formData1[item.prop]"
                  @jumpDetail="jumpDetail(item)"
                />
                <!-- {{ formData1[item.prop] }} -->
              </span>
            </a>
            <!-- 简档、角色、所属公海池不需要跳转 -->
            <span
              class="cursor-pointer allow-click Unallow-click"
              v-else-if="
                item.apiname === 'profile' ||
                item.apiname === 'role' ||
                item.apiname === 'marketsea'
              "
            >
              {{ formData1[item.prop] }}
            </span>
            <!-- 所有人是公海池时不可更改 -->
            <span
              v-if="
                item.apiname === 'ownerid' && canEditOwner && !item.ismarketsea
              "
              class="cursor-pointer allow-click"
              style="margin-left: 8px"
              @click="changeOwner"
            >
              [{{ $t("label.change") }}]
            </span>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 图片 文件类型 -->
          <span
            class="infoItem pic_item_style"
            style="max-height: 300px; overflow: auto"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="
              item.fieldType === 'IMG' && item.expressionType !== 'url'
            "
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <!-- @dblclick="doubleClickItem(item)" -->
            <AddPicModule
              v-if="
                (item.type === 'file' &&
                  item.fieldType === 'IMG' &&
                  item.expressionType !== 'url') ||
                (item.type === 'file' &&
                  item.fieldType === 'IMG' &&
                  item.expressionType == 'lightning-ref')
              "
              :item="item"
              :formData="formData"
              :isEditModel="false"
              :onlyShow="!item.data"
              :isLightningOriginal="item.expressionType == 'lightning-ref'"
            />

            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 图片 引用类型 -->
          <span
            class="infoItem"
            style="max-height: 300px; overflow: auto"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="
              item.fieldType === 'IMG' && item.expressionType === 'url'
            "
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <img
              v-if="
                formData[item.prop] !== undefined &&
                formData[item.prop] !== '' &&
                formData[item.prop] !== null
              "
              :src="formData[item.prop]"
              style="max-width: 100%"
            />
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 文件字段展示样式 -->
          <div
            class="infoItemText"
            v-else-if="item.type === 'file'"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <div>
              <p
                class="cursor-pointer allow-click"
                v-for="(file, fileIdx) in item.data"
                :key="fileIdx"
                @click="openPreview(file)"
              >
                {{ file.type ? `${file.name}.${file.type}` : file.name }}
                <!-- <span v-show="fileIdx+1 !== item.data.length">;</span> -->
              </p>
            </div>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </div>
          <!-- 复选框 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.type === 'checkbox'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <el-checkbox disabled v-model="formData1[item.prop]">
              <!-- {{item.label}} -->
            </el-checkbox>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- URL -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'U'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <!-- twitter字段展示样式 -->
            <svg
              v-if="item.apiname === 'twitter'"
              class="icon linkedinImg"
              aria-hidden="true"
              @click="
                formData1[item.prop] !== '' ? twitterLinkedinFacebook(item) : ''
              "
              @contextmenu.prevent="
                formData1[item.prop] !== '' ? twitterLinkedinFacebook(item) : ''
              "
            >
              <use
                :href="
                  formData1[item.prop] === ''
                    ? '#icon-twitter'
                    : '#icon-twitter_blue'
                "
              ></use>
            </svg>
            <!-- linkedin字段展示样式 -->
            <svg
              v-if="item.apiname === 'linkedin'"
              class="icon linkedinImg"
              aria-hidden="true"
              @click="
                formData1[item.prop] !== '' ? twitterLinkedinFacebook(item) : ''
              "
              @contextmenu.prevent="
                formData1[item.prop] !== '' ? twitterLinkedinFacebook(item) : ''
              "
            >
              <use
                :href="
                  formData1[item.prop] === ''
                    ? '#icon-Linkedin'
                    : '#icon-Linkedin_blue'
                "
              ></use>
            </svg>
            <!-- facebook字段展示样式 -->
            <svg
              v-if="item.apiname === 'facebook'"
              class="icon linkedinImg"
              aria-hidden="true"
              @click="
                formData1[item.prop] !== '' ? twitterLinkedinFacebook(item) : ''
              "
              @contextmenu.prevent="
                formData1[item.prop] !== '' ? twitterLinkedinFacebook(item) : ''
              "
            >
              <use
                :href="
                  formData1[item.prop] === ''
                    ? '#icon-facebook'
                    : '#icon-facebook_blue'
                "
              ></use>
            </svg>
            <!-- twitter、linkedin、facebook只展示图标，不展示url -->
            <span
              v-if="
                item.apiname === 'twitter' ||
                item.apiname === 'linkedin' ||
                item.apiname === 'facebook'
                  ? false
                  : true
              "
              style="color: #489ad9; cursor: pointer"
              @click="jumpTo(formData1[item.prop])"
            >
              {{ formData1[item.prop] }}
            </span>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 标签 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'X' && item.apiname === 'cloudcctag'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <el-tag
              v-for="tag in item.taglist"
              :key="tag.name"
              :type="
                tag.color === 'lan'
                  ? ''
                  : tag.color === 'hong'
                  ? 'danger'
                  : tag.color === 'lv'
                  ? 'success'
                  : tag.color === 'hui'
                  ? 'info'
                  : tag.color === 'huang'
                  ? 'warning'
                  : ''
              "
              effect="dark"
              class="tag"
            >
              {{ tag.name }}
            </el-tag>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 潜在客户打分 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.apiname === 'leadscore'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <span style="width: 20px; display: inline-block">{{
              formData1[item.prop]
            }}</span>
            <el-progress
              :percentage="Number(formData[item.prop])"
              :color="customColors"
              :format="formatProgress"
              style="width: calc(100% - 50px); display: inline-block"
            >
            </el-progress>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 数字 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'N' || item.fieldType === 'C'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            {{ formData1[item.prop] }}
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 日期 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'D'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            {{ formData1[item.prop] | dateFormat(countryCode) }}
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 日期时间 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'F'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            {{ formData1[item.prop] | datetimeFormat(countryCode) }}
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 名称和相关项 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.apiname === 'whoid'"
          >
            <a
              style="color: #489ad9; cursor: pointer"
              :href="hrefUrl(item)"
              @click="detail($event, item)"
            >
              {{ item.child.whoname }}
            </a>
            <!--@click="jumpTo(formData1[item.prop])">{{formData1[item.prop]}}</span>-->
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.apiname === 'relateid'"
          >
            <a
              style="color: #489ad9; cursor: pointer"
              :href="hrefUrl(item)"
              @click="detail($event, item)"
            >
              {{ item.child.relatename }}
            </a>
            <!--@click="jumpTo(formData1[item.prop])">{{formData1[item.prop]}}</span>-->
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 普通字段-展示样式 -->
          <!-- v-if="item.type !== 'file' && item.type !== 'remote-select' && item.type !== 'checkbox'" -->
          <!-- 百分比 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'P'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            {{ formData1[item.prop] }}%
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 地址字段跳转百度地图 -->
          <span
            class="infoItem address_info_style"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'AD'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
            :class="{ is_location_style: itemComputed(item) }"
            @click.stop="mapBtn(item)"
          >
            <!-- 这个是 乎福军 让加的，有经纬度参数则展示图标，编辑页不管 -->
            <span>{{ formData1[item.prop] }}</span>
            <svg
              class="icon address_icon"
              aria-hidden="true"
              v-if="isShowLocationIconFn(item)"
            >
              <use href="#icon-a-Validaddress"></use>
            </svg>

            <svg
              v-if="objectApi != 'cloudccorder' && objectApi != 'Invoices'"
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 评分 -->
          <span
            class="infoItem stars"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.type === 'score'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <span
              v-for="(star, idx) in Number(item.length) + 1"
              v-show="idx !== 0"
              :class="{ show2: idx <= Number(item.value) }"
              :key="idx"
            >
            </span>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 经纬度跳转地图 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
              fontSize: '14px',
              color: '#006dcc',
            }"
            v-else-if="item.fieldType === 'LT'"
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <span class="cursor-pointer" @click="mapBtn(item)">
              {{ formData1[item.prop] }}
            </span>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
          </span>
          <!-- 需求更改：手机号 电话隐藏功能暂时关闭 产品：呼富军 -->
          <!-- 手机号 电话隐藏 -->
          <!-- <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
              fontSize: '14px',
            }"
            v-else-if="item.apiname == 'phone' || item.apiname == 'mobile'"
          >
            <span v-if="item.apiname == 'phone' && !showDetailPh">
              {{ formData1[item.prop] | phoneFilter }}
            </span>
            <span v-if="item.apiname == 'mobile' && !showDetailMb">
              {{ formData1[item.prop] | phoneFilter }}
            </span>
            <span v-if="item.apiname == 'phone' && showDetailPh">
              {{ phoneDetail }}
            </span>
            <span v-if="item.apiname == 'mobile' && showDetailMb">
              {{ phoneDetail1 }}
            </span>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              v-if="item.apiname == 'phone' && formData1[item.prop]"
              @click="showPHdetail(formData1[item.prop], item.apiname)"
            >
              <use
                :href="showDetailPh ? '#icon-show' : '#icon-show_blue'"
              ></use>
            </svg>
            <svg
              class="icon editIcon"
              aria-hidden="true"
              v-if="item.apiname == 'mobile' && formData1[item.prop]"
              @click="showPHdetail(formData1[item.prop], item.apiname)"
            >
              <use
                :href="showDetailMb ? '#icon-show' : '#icon-show_blue'"
              ></use>
            </svg>
            <svg
              class="icon"
              v-if="replyPhoneShow && item.fieldType === 'H' && item.value"
              aria-hidden="true"
              @click="callPhone(item.value)"
              style="cursor: pointer; margin-left: 5px"
            >
              <use href="#icon-Call"></use>
            </svg>
          </span>-->
          <!-- 公式-复选框字段 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'Z' && item.expressionType === 'B'"
          >
            <el-checkbox disabled :value="item.value === 'true'"> </el-checkbox>
          </span>
          <!-- 公式-文本字段使用v-html展示 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
            }"
            v-else-if="item.fieldType === 'Z' && item.expressionType === 'S'"
            v-html="item.value"
          >
          </span>
          <!-- 普通字段-展示样式 -->
          <span
            class="infoItem"
            :style="{
              width:
                item.width === '100%'
                  ? 'calc(100% - 20px)'
                  : 'calc(100% - 20px)',
              color: item.apiname === 'wz' ? '#489ad9' : '',
            }"
            v-else
            @dblclick="doubleClickItem(item)"
            @mouseover="showEditIcon(item)"
            @mouseleave="hideEditIcon"
          >
            <!-- 选项列表和选项列表多选无对应值时显示原值,同时选项列表为空时特殊处理 -->
            <!-- <span v-html="formData1[item.prop]"> </span> -->
            <span
              v-if="item.type === 'select'"
              v-html="
                formData1[item.prop] === undefined ||
                formData1[item.prop] === ''
                  ? formData[item.prop]
                  : formData1[item.prop]
              "
            >
            </span>
            <span
              v-else-if="item.type === 'multi-select'"
              v-html="
                formData1[item.prop] === undefined ||
                formData1[item.prop] === ''
                  ? ''
                  : formData1[item.prop]
              "
            >
            </span>
            <!-- textarea类型使用v-text -->
            <span
              style="white-space: pre-wrap"
              v-else-if="item.type === 'textarea'"
              v-html="
                formData1[item.prop] === undefined ||
                formData1[item.prop] === ''
                  ? ''
                  : formData1[item.prop]
              "
            >
            </span>
            <!-- 访客、语音对象 -->
            <span
              v-else-if="
                (objectApi == 'Visitor' || objectApi == 'Voice') &&
                item.fieldType === 'H' &&
                item.value &&
                hidePhone
              "
              v-html="hidePhoneF(formData1[item.prop])"
            >
            </span>
            <span
              style="white-space: pre-wrap"
              v-else-if="
                (item.fieldType == 'Z' && item.expressionType == 'S') ||
                item.fieldType == 'S' ||
                item.fieldType == 'X' ||
                item.fieldType == 'J'
              "
              v-html="formData1[item.prop]"
            >
            </span>
            <span
              v-else-if="item.fieldType === 'A'"
              v-html="$options.filters.typeA(formData1[item.prop])"
            >
            </span>
            <span
              style="white-space: pre-wrap"
              v-else-if="item.fieldType == 'Z' && item.expressionType == 'P'"
            >
              {{ formData1[item.prop] ? formData1[item.prop] + "%" : "" }}
            </span>
            <!-- 累计汇总字段默认显示两位 -->
            <span
              style="white-space: pre-wrap"
              v-else-if="item.fieldType == 'C'"
            >
              {{ formData1[item.prop] | typeC }}
            </span>
            <span v-else>
              {{ formData1[item.prop] }}
            </span>
            <img
              :src="tianYanChaImg"
              alt=""
              style="
                width: 75px;
                height: 21px;
                cursor: pointer;
                margin-left: 10px;
                margin-top: -7px;
                transform: translate(0px, 4px);
              "
              v-if="
                !isOpen
                  ? false
                  : isEdit === false
                  ? false
                  : objectApi === 'Account' && item.prop === 'name'
                  ? true
                  : false
              "
              @click="tianyan"
            />
            <span
              v-if="
                item.type === 'select' && item.prop === 'recordtype' && isEdit
              "
              class="cursor-pointer allow-click"
              style="margin-left: 8px"
              @click="changeRecordtype(formData[item.prop])"
            >
              [{{ $t("label.change") }}]
            </span>
            <svg
              :class="item.apiname === 'currency' ? 'currencyEditIcon' : ''"
              class="icon editIcon"
              aria-hidden="true"
              style="display: none"
              @click="showEditableCell(item)"
            >
              <use href="#icon-pen"></use>
            </svg>
            <!-- <i class="el-icon-edit editIcon"
               style="display:none;"
               @click="showEditableCell(item)">
            </i> -->
            <!-- 电话字段 -->
            <svg
              class="icon"
              v-if="replyPhoneShow && item.fieldType === 'H' && item.value"
              aria-hidden="true"
              @click="callPhone(item.value)"
              style="cursor: pointer; margin-left: 5px"
            >
              <use href="#icon-Call"></use>
            </svg>
            <voice-player
              v-if="
                item.value &&
                objectApi == 'Voice' &&
                item.fieldId == '20210901zkfvoice0019'
              "
              :voiceRecord="item.value"
            ></voice-player>
          </span>

          <!-- 表单样式 -->
          <div class="editableCell" style="display: none">
            <!-- 普通字段 -->
            <el-input
              v-if="
                (item.type === 'input' ||
                  item.type === 'text' ||
                  item.type === 'password' ||
                  item.type === 'percent-number') &&
                !item.query &&
                !item.filterable
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="item.maxlength"
              auto-complete="off"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              v-model="formData[item.prop]"
            >
              <!-- <template slot="append"
                        v-if="item.type=== 'percent-number'">%</template> -->
            </el-input>
            <!-- 数字 -->
            <el-input-number
              v-if="
                (item.type === 'number' || item.type === 'percent-number') &&
                !item.query
              "
              :ref="item.refKey"
              :disabled="!item.edit"
              :type="item.type"
              :controls="false"
              :placeholder="item.placeholder"
              :min="item.valueInterval.min"
              :max="item.valueInterval.max"
              :precision="item.precision || 0"
              controls-position="right"
              @blur="
                handleBlurInChild(
                  item,
                  formData[item.prop],
                  formData,
                  ...arguments
                )
              "
              :value="formData[item.prop]"
            >
            </el-input-number>
            <!--  动态检索项 -->
            <el-autocomplete
              class="el-input"
              v-if="item.query"
              v-model="formData[item.prop]"
              :fetch-suggestions="item.query"
              :placeholder="item.placeholder"
              @select="selecter(item.selecter, ...arguments)"
            >
            </el-autocomplete>
            <!-- 查找/查找多选字段 -->
            <el-select
              v-if="
                item.type === 'remote-select' ||
                item.type === 'remote-multi-select'
              "
              v-model="formData[item.prop]"
              filterable
              :multiple="item.type === 'remote-multi-select' ? true : false"
              clearable
              :disabled="item.readonly"
              :loading="loading"
              style="width: calc(100% - 130px)"
              @blur="blurEvent"
              @change="clearFilterOptions"
            >
              <el-option
                v-for="item in optionList[item.prop]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span
              v-if="
                (item.type === 'remote-select' ||
                  item.type === 'remote-multi-select') &&
                item.apiname !== 'createbyid' &&
                item.apiname !== 'lastmodifybyid' &&
                item.edit
              "
              class="remoteBtn"
              @click="remoteSearch(item)"
            >
              [查找]
            </span>
            <form-upload
              v-if="item.type === 'file'"
              style="float: left; width: calc(100% - 78px)"
              :disabled="!item.edit"
              :ref="item.refKey"
              :prop="item.prop"
              :size="fileSize"
              :showMsg="item.showMsg"
              :msg="item.msg"
              :limit="Number(item.fieldLength)"
              multiple
              :file-type="item.fileType || []"
              :file-list="item.data"
              :fileAttr="item.fileAttr"
              @fileChange="fileChange"
              @remove="remove"
              @success="success"
              @uploadFileSuccess="uploadFileSuccess"
              v-model="formData[item.prop]"
            >
              {{ item.refKey }}
            </form-upload>
            <!-- 长文本 -->
            <el-input
              v-if="item.type === 'textarea'"
              :disabled="!item.edit"
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="item.maxlength"
              :autosize="{
                minRows: item.minRows || 2,
                maxRows: item.maxRows || 6,
              }"
              v-model="formData[item.prop]"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-input>
            <el-radio-group
              v-model="formData[item.prop]"
              v-if="item.type === 'radio'"
              :disabled="!item.edit"
            >
              <span
                v-for="(child, index) in item.options"
                class="plr-10"
                :key="index"
              >
                <el-radio :label="child.val">{{ child.key }}</el-radio>
              </span>
            </el-radio-group>
            <!-- 多选框  用checkedLabel和 checkedKey来区分取值 -->
            <el-checkbox-group
              v-model="formData[item.prop]"
              v-if="item.type === 'checked'"
              :disabled="!item.edit"
            >
              <template v-for="(checkedItem, index) in item.checkedList">
                <el-checkbox
                  :label="checkedItem[item.checkedKey]"
                  :name="item.prop"
                  @change="changeEvent(item.changeEvent, arguments[0], item)"
                  :key="index"
                >
                  {{ checkedItem[item.checkedLabel] }}
                </el-checkbox>
              </template>
            </el-checkbox-group>
            <!-- 选项列表/选项列表多选 -->
            <el-select
              v-if="item.type === 'select' || item.type === 'multi-select'"
              v-model="formData[item.prop]"
              :disabled="!item.edit"
              clearable
              :multiple="item.type === 'multi-select' ? true : false"
              :filterable="filterable"
              :placeholder="item.placeholder"
              @visible-change="visibleChange"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
              <template v-for="opt in optionList[item.prop]">
                <el-option :key="opt.key" :label="opt.key" :value="opt.val">
                </el-option>
              </template>
            </el-select>
            <el-checkbox
              v-if="item.type === 'checkbox'"
              :disabled="!item.edit"
              v-model="formData[item.prop]"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
              {{ item.label }}
            </el-checkbox>
            <!-- 级联选择器 -->
            <el-cascader
              v-if="item.type === 'cascader'"
              :disabled="!item.edit"
              v-model="formData[item.prop]"
              :options="item.options"
              style="width: 100%"
              filterable
              :show-all-levels="item.showAllLevels || false"
            >
            </el-cascader>
            <!-- 日期 -->
            <el-date-picker
              v-if="item.type === 'date'"
              :disabled="!item.edit"
              :editable="false"
              v-model="formData[item.prop]"
              type="date"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy-MM-dd"
              :format="dateFormat"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- 日期时间 -->
            <el-date-picker
              v-if="item.type === 'datetime'"
              :disabled="!item.edit"
              :editable="false"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              :format="datetimeFormat"
              v-model="formData[item.prop]"
              type="datetime"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>
            <!-- <el-date-picker v-if="item.type === 'datetime'"
                          :disabled="!item.edit"
                          :editable="false"
                          :default-time="item.picker && item.picker.defaultTime"
                          value-format='yyyy-MM-dd'
                          v-model="formData[`${item.prop}Date`]"
                          type="date"
                          :picker-options="item.picker"
                          :placeholder="item.placeholder"
                          @change="changeEvent(item.changeEvent,arguments[0],item,'datetime')">
          </el-date-picker>
          <el-time-select v-if="item.type === 'datetime'"
                          :disabled="!item.edit"
                          value-format='HH:mm:ss'
                          v-model="formData[`${item.prop}Time`]"
                          :picker-options="{start: '00:00',step: '00:15',end: '23:45'}"
                          :placeholder="item.placeholder"
                          @change="changeEvent(item.changeEvent,arguments[0],item,'datetime')">
          </el-time-select> -->
            <!-- 时间 -->
            <el-time-select
              v-if="item.type === 'time'"
              :disabled="!item.edit"
              value-format="HH:mm:ss"
              :format="timeFormat"
              v-model="formData[item.prop]"
              :picker-options="{ start: '00:00', step: '00:15', end: '23:45' }"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-time-select>
            <!-- 年份 -->
            <el-date-picker
              v-if="item.type === 'dateYear'"
              :disabled="!item.edit"
              :editable="false"
              :default-time="item.picker && item.picker.defaultTime"
              value-format="yyyy"
              v-model="formData[item.prop]"
              type="year"
              :picker-options="item.picker"
              :placeholder="item.placeholder"
              @change="changeEvent(item.changeEvent, arguments[0], item)"
            >
            </el-date-picker>

            <i
              class="el-icon-check confirmIcon"
              style="
                margin-right: 5px;
                color: #4bca80;
                border: 1px solid #4bca80;
              "
              @click="saveEditableCell"
            >
            </i>
            <i
              class="el-icon-close confirmIcon"
              style="color: #dedcda"
              @click="hideEditableCell"
            >
            </i>
          </div>
          <!-- 提醒 -->
          <span class="remind-text" v-if="item.remind">
            {{ item.remind }}
          </span>
        </el-form-item>
      </div>
    </el-form>

    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <div
        :style="{
          'min-height': dialogBodyHeight,
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <search-table
          ref="searchTable"
          :dialogBodyHeight="dialogBodyHeight"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelect"
          @setFieldReltaion="setFieldReltaion"
        />
      </div>
    </el-dialog>

    <!-- 天眼查 -->
    <tian-yan-cha ref="tian"></tian-yan-cha>
    <!-- 文件预览 -->
    <file-preview
      :show="showPreview"
      :showData="showPreviewData"
      :configButtm="configButtm"
      @closePreview="closePreview"
      ref="previewFile"
    >
    </file-preview>

    <el-dialog
      title=""
      :width="mapWidth"
      :top="mapTop"
      :visible.sync="bigMapDialog"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
      custom-class="big-view-box"
      @click.stop
    >
      <!-- 高德/谷歌地图 -->
      <map-group
        @click.stop
        ref="mapGroup"
        :mapkey="mapkey"
        :pointValue="mapData"
        :ggPointValue="mapObjectData"
        :lineIndex="lineIndex"
        :isInfoMap="isInfoMap"
        :isMap="isMap"
        @bigClose="bigClose"
      ></map-group>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import { mapState } from "vuex";
// import SearchTable from "./search-table.vue";
import FormUpload from "@/components/Upload/formUpload";
import { IEVersion } from "@/utils/brower";
import * as CommonObjApi from "./api";
import { consoleSaveTab } from "@/views/home/api.js";
import * as Time from "@/utils/time";
import tianYanCha from "../../components/Tianyancha";
import filePreview from "@/components/FileView/filePreview";
import PopoverCpn from "@/views/homePage/components/PopoverCpn.vue"; //悬浮卡片
import VoicePlayer from "./components/voicePlayer.vue";
import * as callCenterApi from "./api.js"; // 呼叫中心接口
import MapGroup from "@/components/Map/index.vue";
import operator from "@/config/enumCode/operator.js";
import { CCRenderer } from "cloudcc-ui-beta";
export default {
  props: {
    // 记录id
    recordId: {
      type: String,
      default: "",
    },
    formAttr: {
      type: Array,
    },
    isShowType: {
      type: Boolean,
      default: false,
    }, //是否用户360
    optionList: {
      type: Object,
      default: () => {},
    },
    labelPosition: {
      type: String,
      default: "left",
    },
    width: {
      type: String,
    },
    // 几列布局
    columnNum: {
      type: Number,
      default: 2,
    },
    // 一列占宽百分比
    oneColumnWidth: {
      type: String,
      default: "100%",
    },
    // 两列占宽百分比
    twoColumnWidth: {
      type: String,
      default: "50%",
    },
    statusIcon: {
      // 是否显示验证
      type: Boolean,
      default: true,
    },
    labelWidth: {
      type: Number,
      String,
      default: 120,
    },
    handleBlur: {
      // 处理移出事件
      type: [Function, undefined],
      default: undefined,
    },
    // 重复渲染
    autoRefresh: {
      // 值发生变化时是否自动刷新
      type: Boolean,
      default: true,
    },
    disabled: {
      // 是否
      type: Boolean,
      default: false,
    },
    operation: {
      type: String,
      default: "",
    },
    isEdit: {
      // 记录编辑权限
      type: Boolean,
      default: false,
    },
    canEditOwner: {
      // 更改所有人权限
      type: Boolean,
      default: false,
    },
    objectApi: {
      type: String,
      default: "",
    },
    // 对象id
    objId: {
      type: String,
      default: "",
    },
    inlineedit: {
      // 全局内联编辑权限
      type: Boolean,
      default: false,
    },
    isNotPersonaccount: {
      // 不是个人客户
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormUpload,
    CCRenderer,
    tianYanCha,
    filePreview,
    PopoverCpn,
    VoicePlayer,
    MapGroup,
    AddPicModule: () => import("./components/AddPicModule.vue"),
  },
  filters: {
    phoneFilter(val) {
      if (val != null) {
        let _phone = val.substring(0, 3);
        let _phoneLength = val.length - 3;
        while (_phoneLength > 0) {
          _phone += "*";
          _phoneLength--;
          return _phone;
        }
      } else {
        return "";
      }
    },
    mobileFilter(val) {
      return val.replace(/^(.{3}).*(.{4})$/, "$1****$2");
    },
    typeC(val) {
      if (val && val !== null) {
        return Number(val).toFixed(2);
      }
    },
    typeA(val) {
      if (val && val !== null) {
        return val.split("&lt;").join("<").split("&gt;").join(">");
      }
    },
  },
  data() {
    return {
      // 配置预览组件显示的按钮
      configButtm: ["download"],
      //币种是否允许编辑
      currencyEditShow: false,
      hidePhone: false,
      sss: 0,
      hideSelectionId: [], // 收起部分id
      countryCode: $cookies.get("countryCode"),
      showPreview: false, // 预览
      showPreviewData: {}, // 预览数据
      previewUrl: "", // 预览地址
      showSearchTable: false,
      fieldId: "",
      checked: false,
      relevantObjId: "",
      relevantPrefix: "",
      props: {
        checkStrictly: true,
        emitPath: false,
      },
      formData: {}, // 当前表格数据,
      formData1: {},
      rules: {}, // 验证规则
      filterable: true, // 是否可检索
      loading: false, // 远程搜索loading
      filterOptions: [], // 远程搜索用于显示的数组
      _timeOut: null, // 当远程搜索频繁改变时用setTimeout减少发送
      fileSize: 1024,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      dateFormat: Time.dateFormat($cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat($cookies.get("countryCode")),
      timeFormat: Time.timeFormat($cookies.get("countryCode")),
      customColors: [
        { color: "#70acf1", percentage: 20 },
        { color: "#f7c97e", percentage: 40 },
        { color: "#faa58c", percentage: 100 },
      ],
      userUrl: "",
      token: this.$CCDK.CCToken.getToken(),
      tianYanChaImg: require("@/assets/images/tianyancha.svg"),
      userInfo: {},
      isOpen: false,
      twitter: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("twitter.com") === -1) {
            this.isTwitter = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.twittererror"))
            );
          } else {
            this.isTwitter = true;
            callback();
          }
        }
      },
      linkedin: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("linkedin.com") === -1) {
            this.isLinkedin = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.linkedinerror"))
            );
          } else {
            this.isLinkedin = true;
            callback();
          }
        }
      },
      facebook: (rule, value, callback) => {
        if (!value) {
          callback();
        } else {
          if (value.indexOf("facebook.com") === -1) {
            this.isFacebook = false;
            return callback(
              new Error(this.$i18n.t("APPIntegration.Social.facebookerror"))
            );
          } else {
            this.isFacebook = true;
            callback();
          }
        }
      },
      phoneDetail: "", //(电话 手机号字段隐藏用)
      phoneDetail1: "", //(电话 手机号字段隐藏用)
      showDetailPh: false, //(电话 手机号字段隐藏用)
      showDetailMb: false, //(电话 手机号字段隐藏用)
      bigMapDialog: false,
      mapkey: "",
      lineIndex: Math.ceil(Math.random() * 100),
      mapWidth: "80%",
      mapTop: "6%",
      isMap: true,
      mapData: [],
      mapObjectData: {},
      isInfoMap: true,
      replyPhoneShow: false,
      countryCodeCall:
        this.$store.state.userInfoObj.language == "zh" ? "CN" : "",
    };
  },
  created() {
    this.getLeadHeat();
    this.filterFormData();
    this.getUserInfo();
    // 天眼查是否启用设置，只有'客户'菜单下才需要天眼查
    if (this.objectApi === "Account") {
      this.isOpenFun();
    }
    // 如果是edge浏览器 将select可搜索改为false
    if (IEVersion() === "edge") {
      this.filterable = false;
    }
    this.getPhoneHidden();
  },
  beforeDestroy() {
    this.$bus.$off("windowResize", this.setDialogHight);
  },
  mounted() {
    this.hideSelectionId = [];
    this.$bus.$on("windowResize", this.setDialogHight);
    this.replyPhoneShowClick();
  },
  methods: {
    setDialogHight(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    // 电话回复图标判断
    replyPhoneShowClick() {
      let callcenterConf = JSON.parse(localStorage.getItem("callcenterConf"));
      if (callcenterConf?.tooltype) {
        this.replyPhoneShow = true;
      } else {
        this.replyPhoneShow = false;
      }
      // if (this.countryCodeCall == "CN") {
      //   let paramsInfo = {
      //     intergrateType: "tianrun",
      //   };
      //   // 获取呼叫中心设置
      //   callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
      //     if (resInfo.result && resInfo.data) {
      //       if (resInfo.data.isEnable == "0") {
      //         this.replyPhoneShow = false;
      //       } else if (resInfo.data.isEnable == "1") {
      //         let params = {
      //           bindUserId: localStorage.getItem("userId"),
      //         };
      //         callCenterApi.getBindByCnoOrUserId(params).then((res) => {
      //           if (res.data.length !== 0) {
      //             this.replyPhoneShow = true;
      //           } else {
      //             this.replyPhoneShow = false;
      //           }
      //         });
      //       }
      //     }
      //   });
      // } else {
      //   let paramsInfo = {
      //     intergrateType: "aws",
      //   };
      //   // 获取呼叫中心设置
      //   callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
      //     if (resInfo.result && resInfo.data) {
      //       if (resInfo.data.isEnable == "0") {
      //         this.replyPhoneShow = false;
      //       } else if (resInfo.data.isEnable == "1") {
      //         this.replyPhoneShow = true;
      //       }
      //     }
      //   });
      // }
    },

    hidePhoneF(val) {
      let _phone = val.substring(0, 3);
      let _phoneLength = val.length - 3;
      while (_phoneLength > 0) {
        _phone += "*";
        _phoneLength--;
      }
      return _phone;
    },
    getPhoneHidden() {
      if (this.countryCodeCall == "CN") {
        this.countryBool = true;
      } else {
        this.countryBool = false;
      }
      let paramsInfo = {
        intergrateType: this.countryBool ? "tianrun" : "aws",
      };
      // 获取呼叫中心设置
      callCenterApi.getCallCenterInfo(paramsInfo).then((resInfo) => {
        if (resInfo.result && resInfo.data) {
          if (resInfo.data.isEnable == "0") {
            this.hidePhone = false;
          } else if (resInfo.data.isEnable == "1") {
            if (resInfo.data.ishidden == "1") {
              this.hidePhone = true;
            } else if (resInfo.data.ishidden == "0") {
              this.hidePhone = false;
            }
          }
        }
      });
    },

    //悬浮卡片跳转到记录详情
    jumpDetail(item) {
      if (item.objid === "lightningreport") {
        // 属于报表
        this.$router.push({
          path: `/reportObject/editReport/run/${item.value}`,
          query: {},
        });
      } else if (item.objid === "dashboardlightning") {
        // 属于仪表板
        this.$router.push({
          path: `/dashboardObject/dashboardIndex/${item.value}`,
          query: {},
        });
      } else if (item.objid === "file") {
        //属于文件
        this.$router.push({
          path: "/ccFileCenter/fileDetail",
          query: {
            id: item.value,
          },
        });
      } else {
        // 属于通用对象
        let path = this.consoleJump(item.data[0], item);
        this.$router.push({
          path: path,
        });
      }
    },
    // 进度条格式化
    formatProgress() {
      return "";
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    // 处理帮助文本特殊字符回车显示#enter类似问题
    helpText(helpText) {
      if (helpText === undefined || helpText === null) {
        return helpText;
      } else {
        return helpText
          .replace(/#enter/g, "<br />")
          .replace(/#quoteDouble/g, '"')
          .replace(/#quoteSingle/g, "'")
          .replace(/#quote/g, "'");
      }
    },
    // 部分展开收起
    toggleGroup(group) {
      let index = this.hideSelectionId.indexOf(group.selectionId);
      if (index === -1) {
        this.hideSelectionId.push(group.selectionId);
      } else {
        this.hideSelectionId.splice(index, 1);
      }
    },
    // // 预览图片
    // previewImg(id) {
    //   this.showPreview = true;
    //   this.showPreviewData = {
    //     type: "jpg",
    //     id,
    //     isUseNativePreview: true, //不使用插件进行图片预览
    //   };
    //   this.$refs.previewFile.handleBtn(["download"]);
    // },
    // 潜在客户热度设置信息
    getLeadHeat() {
      if (localStorage.getItem("coldLevel") === null) {
        CommonObjApi.getLeadHeat({}).then((res) => {
          localStorage.setItem("coldLevel", Number(res.data.coldLevel) + 1);
          localStorage.setItem("hotLevel", Number(res.data.hotLevel) + 1);

          this.customColors[0].percentage = localStorage.getItem("coldLevel");
          this.customColors[1].percentage = localStorage.getItem("hotLevel");
        });
      } else {
        this.customColors[0].percentage = localStorage.getItem("coldLevel");
        this.customColors[1].percentage = localStorage.getItem("hotLevel");
      }
    },
    // 双向绑定 form name
    filterFormData() {
      this.rules = {};
      this.formData = {};
      this.formData1 = {};
      //获取资源属性字段
      let serveSourcefieldType = {};
      if (this.objectApi == "WorkRule") {
        serveSourcefieldType =
          this.formAttr &&
          this.formAttr.find((item) => {
            return item.apiname == "resourcespropertyfield";
          });
      }
      this.formAttr &&
        this.formAttr.forEach((v, k) => {
          this.$set(this.formData, v.prop, v.value);
          // 选项列表、选项列表多选、查找、查找多选显示需对应选项值
          if (
            v.type !== "select" &&
            v.type !== "multi-select" &&
            v.type !== "remote-select" &&
            v.type !== "remote-multi-select"
          ) {
            this.$set(this.formData1, v.prop, v.value);
          } else if (v.name === "createbyid" && v.name === "lastmodifybyid") {
            this.$set(this.formData1, v.prop, v.data[0].value);
          } else {
            let curOptions = this.optionList[v.prop];
            let values = Array.isArray(v.value)
              ? v.value
              : v.value === undefined || v.value === null
              ? []
              : v.value.split(";");
            let curValue = [];
            if (v.type === "select" || v.type === "multi-select") {
              values.forEach((value) => {
                curOptions &&
                  curOptions.forEach((option) => {
                    // 不确定是key还是val
                    if (option.val === value || option.key === value) {
                      curValue.push(option.key);
                    }
                  });
              });
            } else {
              values.forEach((value) => {
                curOptions &&
                  curOptions.forEach((option) => {
                    // 不确定是value还是label
                    if (option.value === value || option.label === value) {
                      curValue.push(option.label);
                    }
                  });
              });
            }
            this.$set(this.formData1, v.prop, curValue.join(";"));
          }
          // 记录类型字段显示取id
          if (v.prop === "recordtype") {
            this.$set(this.formData, v.prop, v.id);
          }

          // 如果有验证规则
          if (v.rules && v.rules.length) {
            let name = v.prop;
            v.rules.forEach((val, key) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });
          }
          if (
            this.objectApi == "WorkRule" &&
            v.apiname == "resourcespropertyfield"
          ) {
            // 工作规则-资源属性字段
            if (v.apiname == "resourcespropertyfield") {
              // 获取选项
              if (v.value) {
                CommonObjApi.getResourceAttributeFields().then((res) => {
                  let optionData = [];
                  res.data.forEach((item) => {
                    optionData.push(...item.fieldList);
                  });
                  // 匹配出已选的那条数据
                  let option = optionData.find((item) => {
                    return item.id === v.value;
                  });
                  if (option) {
                    this.$set(this.formData1, v.prop, option.labelName);
                    // 加载运算符的选项
                    let conditions =
                      operator.TYPE[
                        option.schemefieldType
                      ].getScreeningOpeTypes();
                    this.formAttr.forEach((item) => {
                      // 运算符
                      if (item.apiname == "fieldoperator" && item.value) {
                        let val = conditions.find((ele) => {
                          return ele.id === item.value;
                        }).name;
                        this.$set(this.formData1, item.prop, val);
                      }
                    });
                  }
                });
              }
            }
          }
          if (
            this.objectApi == "WorkRule" &&
            v.apiname == "resourcespropertyvalue"
          ) {
            if (v.value) {
              CommonObjApi.getResourceAttributeFields().then((res) => {
                let optionData = [];
                res.data.forEach((item) => {
                  optionData.push(...item.fieldList);
                });
                let serveSourcefieldValue = optionData.find((item) => {
                  return item.id == serveSourcefieldType.value;
                });
                if (
                  serveSourcefieldValue &&
                  serveSourcefieldValue.schemefieldType == "P"
                ) {
                  // 获取资源属性字段类型，如果是百分比设置资源属性值添加%
                  this.$set(
                    this.formData1,
                    "resourcespropertyvalue",
                    v.value + "%"
                  );
                } else if (
                  serveSourcefieldValue &&
                  serveSourcefieldValue.schemefieldType == "B"
                ) {
                  // 资源属性值==true||false时，字段类型设置为复选框
                  if (v.value === "true" || v.value === true) {
                    v.value = true;
                  } else if (v.value === "false" || v.value === false) {
                    v.value = false;
                  }
                  this.$set(this.formData1, v.prop, v.value);
                  v.type = "checkbox";
                }
              });
            }
          }
          // 服务目标，字段资格 ----开始
          if (
            this.objectApi == "ServiceGoal" &&
            v.apiname == "qualificationfield"
          ) {
            this.formAttr.forEach((item) => {
              //查看自定义目标类型的值是否是资源资格, 注：接口返回的value就是中文
              if (
                item.apiname == "customobjectivetype" &&
                item.value == "资源资格"
              ) {
                CommonObjApi.getQualificationFields().then((res) => {
                  let optionData = [];
                  res.data.forEach((item) => {
                    optionData.push(...item.fieldList);
                  });
                  // 给资源属性字段赋值选项
                  let val = optionData.find((ele) => {
                    return ele.id === v.value;
                  });
                  this.$set(
                    this.formData1,
                    v.prop,
                    val ? val.labelName : v.value
                  );
                });
              }
            });
          }
        });

      // 组件内部处理完formData后表单才显示，父组件才能通过$refs获取到表单组件
      this.$emit("ruleProcessing");
    },
    // 重置表单规则
    resetRules() {
      this.rules = {};
      this.formAttr &&
        this.formAttr.forEach((v, k) => {
          // 如果有验证规则
          if (v.rules && v.rules.length) {
            let name = v.prop;
            v.rules.forEach((val, key) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            // twitter,linkedin,facebook有验证规则
            if (v.apiname === "twitter") {
              this.rules[name].push({ validator: this.twitter });
            } else if (v.apiname === "linkedin") {
              this.rules[name].push({ validator: this.linkedin });
            } else if (v.apiname === "facebook") {
              this.rules[name].push({ validator: this.facebook });
            }

            if (v.fieldType === "E") {
              this.rules[name].push({ validator: this.isEmail });
            }
          } else {
            if (v.fieldType === "E") {
              let arr = [{ validator: this.isEmail }];
              this.$set(this.rules, name, arr);
            }
            // twitter,linkedin,facebook无验证规则
            if (v.apiname === "twitter") {
              let arr = [{ validator: this.twitter }];
              this.$set(this.rules, v.prop, arr);
            } else if (v.apiname === "linkedin") {
              let arr = [{ validator: this.linkedin }];
              this.$set(this.rules, v.prop, arr);
            } else if (v.apiname === "facebook") {
              let arr = [{ validator: this.facebook }];
              this.$set(this.rules, v.prop, arr);
            }
          }
        });
    },
    // 获取表单数据
    getData(callback) {
      // 等待修改
      let index = this.filterOptions.findIndex((item, index) => {
        return this.formData.company === item.value;
      });
      // 改动原因: 需要判断当前选择的公司是否在会员机构之内
      if (index !== -1) {
        this.formData.isCompany = true;
      } else {
        this.formData.isCompany = false;
      }
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          callback(this.formData);
        } else {
          return false;
        }
      });
    },
    closePreview() {
      // 列表打开预览
      this.showPreview = false;
      this.previewUrl = "";
    },
    // url跳转
    jumpTo(url) {
      if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
        window.open(url);
      } else {
        window.open("http://" + url);
      }
    },
    /**
     * 打开文件预览
     */
    async openPreview(item) {
      this.showPreview = true;
      this.showPreviewData = item;
      // 传入按钮如果不需要按钮，可以传空数组
      this.$refs.previewFile.handleBtn(["download"]);
    },
    // 文件下载
    downLoadFile(file) {
      let baseURL = this.$baseConfig.baseURL;
      let token = this.$CCDK.CCToken.getToken();
      var link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${file.id}`;
      link.click();
    },
    // 日期格式化
    formatDate(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D}`;
      }
      return dateStr;
    },
    // 日期时间格式化
    formatDateTime(date) {
      let dateStr = "";
      let dateRes = new Date(date);
      const Y = dateRes.getFullYear();
      const M =
        dateRes.getMonth() + 1 < 10
          ? "0" + (dateRes.getMonth() + 1)
          : dateRes.getMonth() + 1;
      const D = dateRes.getDate();
      const h =
        dateRes.getHours() < 10 ? `0${dateRes.getHours()}` : dateRes.getHours();
      const m =
        dateRes.getMinutes() < 10
          ? `0${dateRes.getMinutes()}`
          : dateRes.getMinutes();
      const s =
        dateRes.getSeconds() < 10
          ? `0${dateRes.getSeconds()}`
          : dateRes.getSeconds();
      if (isNaN(date) && !isNaN(Date.parse(date))) {
        dateStr = `${Y}/${M}/${D} ${h}:${m}:${s}`;
      }
      return dateStr;
    },
    // 清除验证规则
    resetFiled() {
      this.$refs.elForm && this.$refs.elForm.resetFields();
    },
    // 双击编辑
    doubleClickItem(item) {
      // 实际人工成本、预计人工成本 项目任务/问题-总持续时长 不能进行双击编辑
      if (
        this.objectApi === "cloudcc_real_labor_costs" ||
        this.objectApi === "cloudccPlanCost" ||
        ((this.objectApi === "cloudccTask" ||
          this.objectApi === "CloudccProblem") &&
          item.apiname === "continue_date")
      ) {
        return;
      }
      // 公式字段和累计汇总字段详情页可点击按钮但实际无法编辑，建议直接屏蔽内联编辑按钮
      if (item.fieldType === "Z" || item.fieldType === "C") {
        return;
      }
      if (
        item.edit &&
        this.isEdit &&
        this.inlineedit &&
        this.objId !== "user"
      ) {
        this.showEditableCell();
        this.$emit("positionEditableCell", item);
      }
    },
    // 显示编辑按钮
    showEditIcon(item) {
      // 详情页内联编辑受后台设置控制
      if (this.$store.state.userInfoObj.enableInlineEdit === "false") return;

      // 详情页内联编辑'币种'字段编辑权限处理
      if (item.apiname === "currency") {
        // 订单、报价单、发票、采购订单、业务机会（详情页）币种'子记录'不为空时不可编辑
        if (
          this.objectApi === "quote" ||
          this.objectApi === "Invoices" ||
          this.objectApi === "cloudccorder" ||
          this.objectApi === "cloudccpurchaseorder" ||
          this.objectApi === "Opportunity"
        ) {
          /**
           * 订单、报价单、发票、采购订单、业务机会（相关列表页内联编辑）修改币种时，
           * 需查看相关子产品是否有数据，有则不可内联编辑，无则显示编辑按钮
           * @param {String} objId 对象id
           * @param {String} recordId 相关记录id
           */
          let params = {
            objId: this.objId,
            recordId: this.recordId,
          };
          CommonObjApi.existRelatedProductInfo(params).then((res) => {
            if (res.data == "true") {
              document.getElementsByClassName(
                "currencyEditIcon"
              )[0].style.display = "none";
            } else {
              document.getElementsByClassName(
                "currencyEditIcon"
              )[0].style.display = "block";
            }
          });
        }
        // 除（订单、报价单、发票、采购订单、业务机会）其他对象币种字段支持内联编辑
        // 这里特殊判断了'产品'对象，产品任何情况下都不支持编辑
        else if (
          (this.objectApi != "quote" ||
            this.objectApi != "Invoices" ||
            this.objectApi != "cloudccorder" ||
            this.objectApi != "cloudccpurchaseorder" ||
            this.objectApi != "Opportunity") &&
          this.objectApi != "product"
        ) {
          document.getElementsByClassName("currencyEditIcon")[0].style.display =
            "block";
        }
        // '产品'对象，不支持编辑
        else if (this.objectApi == "product") {
          document.getElementsByClassName("currencyEditIcon")[0].style.display =
            "none";
        }
      }

      // 实际人工成本、预计人工成本 项目任务/问题-总持续时长/日程预约 不展示列表行内编辑
      if (
        this.objectApi === "cloudcc_real_labor_costs" ||
        this.objectApi === "cloudccPlanCost" ||
        ((this.objectApi === "cloudccTask" ||
          this.objectApi === "CloudccProblem") &&
          item.apiname === "continue_date") ||
        this.objectApi === "Appointment"
      ) {
        event.target.getElementsByClassName("editIcon")[0]
          ? (event.target.getElementsByClassName("editIcon")[0].style.display =
              "none")
          : "";
        return;
      }
      //  && !this.isUser360
      if (
        item.edit &&
        this.isEdit &&
        this.objId !== "user" &&
        item.apiname !== "currency"
      ) {
        if (event.target.getElementsByClassName("editIcon")[0]) {
          event.target.getElementsByClassName("editIcon")[0].style.display = "";
        }
      }
      // 公式字段和累计汇总字段详情页可点击按钮但实际无法编辑，建议直接屏蔽内联编辑按钮
      if (item.fieldType === "Z" || item.fieldType === "C") {
        if (event.target.getElementsByClassName("editIcon")[0]) {
          event.target.getElementsByClassName("editIcon")[0].style.display =
            "none";
        }
      }
    },
    // 隐藏编辑按钮
    hideEditIcon(event) {
      event.target.getElementsByClassName("editIcon")[0].style.display = "none";
      // 重置formData
      // this.formData = {}
      // this.formAttr && this.formAttr.forEach((v, k) => {
      //   this.$set(this.formData, v.prop, v.value)
      // })
    },
    // 显示编辑框
    showEditableCell(item) {
      if (item === "cloudcctag") {
        this.$emit("editCloudcctag");
      } else {
        this.$emit("showEditableCell");
        // 判断item是否为object类型
        if (typeof item === "object") {
          this.$emit("positionEditableCell", item);
        }
      }
    },
    // 保存数据并隐藏编辑框
    saveEditableCell(event) {
      // this.formData1 = JSON.parse(JSON.stringify(this.formData))
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          event.target.parentElement.parentElement.getElementsByClassName(
            "editableCell"
          )[0].style.display = "none";
          event.target.parentElement.parentElement.getElementsByClassName(
            "infoItem"
          )[0].style.display = "";
          this.$emit("saveEditableCell");
        } else {
          // this.$message.error("多个字段验证错误，请使用右上角编辑按钮进行修改");
        }
      });
    },
    // 隐藏编辑框
    hideEditableCell(event) {
      event.target.parentElement.parentElement.getElementsByClassName(
        "editableCell"
      )[0].style.display = "none";
      event.target.parentElement.parentElement.getElementsByClassName(
        "infoItem"
      )[0].style.display = "";
    },
    // 查找/查找多选
    remoteSearch(item) {
      this.fieldId = item.fieldId;
      this.relevantObjId = item.objId;
      this.relevantPrefix = item.prefix;
      this.showSearchTable = true;
      this.checked = item.fieldType === "MR" ? true : false;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 改变查找/查找多选的值
    changeSelect(row) {
      this.showSearchTable = false;
      this.$emit("changeSelect", row, this.fieldId, this.formData);
    },
    // 设置查找筛选条件
    setFieldReltaion(filterFieldIds) {
      let values = [];
      for (let reltaion in filterFieldIds) {
        let attr = this.formAttr.find((item) => {
          return item.fieldId === filterFieldIds[reltaion].fieldid;
        });
        if (attr !== undefined && Array.isArray(this.formData[attr.prop])) {
          values.push(this.formData[attr.prop].join(";"));
        } else if (attr !== undefined) {
          values.push(this.formData[attr.prop]);
        }
      }
      this.$refs.searchTable.filterConditionVals = values;
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 更改所有人
    changeOwner() {
      this.$emit("changeOwner");
    },
    //更改地址
    changeAddress(item) {
      //客户字段不为空时执行
      if (
        (this.formData1.accountid != undefined &&
          this.formData1.accountid != "") ||
        (this.formData1.account_name != undefined &&
          this.formData1.account_name != "")
      ) {
        this.$emit(
          "changeAddress",
          this.formData.accountid || this.formData.account_name,
          item.apiname,
          this.formData1.accountid || this.formData1.account_name
        );
      } else {
        this.$message(this.$i18n.t("c569"));
      }
    },
    // 远程搜索方法
    remoteMethod(query) {
      if (this.formData.isAssociator === "1") {
        if (query !== "") {
          clearTimeout(this._timeOut);
          this.loading = true;
          this._timeOut = setTimeout(() => {});
        } else {
          this.filterOptions = [];
        }
      }
    },
    // 远程搜索完成选择后清空搜索数组
    clearFilterOptions() {
      this.filterOptions.splice(0);
    },
    // 远程搜索框失去焦点后清空查询列表
    blurEvent() {
      // this.filterOptions = []
    },
    // 跳转路径
    hrefUrl(item) {
      // 服务云下的权利详情下的权利过程会根据简档权限跳转lightning设置
      if (
        item.apiname !== "slaprocessid" &&
        item.apiname !== "servicereporttemplateid"
      ) {
        // 导航样式为控制台样式
        let path;
        if (this.$store.state.navigationStyle) {
          // 是
          path = `#/commonObjects/console-multi-screen/console-detail/${item.value}/DETAIL?dataId=${item.value}`;
        } else {
          path = `#/commonObjects/detail/${item.value}/DETAIL`;
        }
        return item.value ? path : "";
      } else {
        return `javascript:void(0);`;
      }
    },
    /**
     * 服务控制台跳转方法
     * @param {Object} tabObj {id:对象id,value:对象名称}
     *
     */
    consoleJump(tabObj, item) {
      let path = "";
      let jumpId = tabObj.id;
      // 详细信息中的 “查找联系人字段” 是个人客户生成的联系人跳转对应的客户详情页
      if (item && item.apiname === "lxrxm" && item.ispersonaccount === "true") {
        jumpId = item.accountid;
      }
      // 导航样式为控制台样式
      if (this.$store.state.navigationStyle) {
        // 是
        path = `/commonObjects/console-multi-screen/console-detail/${jumpId}/DETAIL?dataId=${jumpId}`;
        // 将当前点击数据的id和name 放到当前选中的一级tab下的children里
        // 当前一级tab的对象
        let currentLevelTab = {};
        this.consoleData.data.list.forEach((level1Item) => {
          if (level1Item.id == this.consoleData.data.level1Id) {
            // 设置二级带单选中样式
            level1Item.level2Id = jumpId;
            // 当前一级tabid
            currentLevelTab.id = level1Item.id;
            // 当前一级tabname
            currentLevelTab.name = level1Item.name;
            // 当前一级菜单路由  有可能是通过ccdk生成的一级菜单
            currentLevelTab.routerName = level1Item.routerName || "";
            currentLevelTab.routerPath = level1Item.routerPath || "";
            // 当前一级菜单路由参数
            currentLevelTab.params = level1Item.params || "";
            currentLevelTab.query = level1Item.query || "";
            level1Item.children = level1Item.children
              ? level1Item.children
              : [];
            // 判断children里是否存在此点击项 不存在再添加避免重复添加
            // 判断是否存在此点击项
            let res = level1Item.children?.some((cItem) => {
              return cItem.id == jumpId;
            });
            if (!res) {
              // 如果不存在再push
              level1Item.children.push({
                id: jumpId,
                name: tabObj.value,
              });
              // 二级tab要展示当前一级tab  判断如果没有当前一级tab 则添加到第一项
              let flag = level1Item.children.some((cItem) => {
                return cItem.id == this.consoleData.data.level1Id;
              });
              if (!flag) {
                level1Item.children.unshift(currentLevelTab);
              }
            }
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      } else {
        path = `/commonObjects/detail/${jumpId}/DETAIL`;
      }
      return path;
    },
    // 点击查找/主详字段跳转到对应对象的详情页
    // 简档  profile    角色 role
    detail(event, item) {
      // 判断服务云下的权利详情下的权利过程根据简档权限跳转lightning设置
      if (this.objectApi === "Entitlement" && item.apiname === "slaprocessid") {
        if (this.$store.state.userInfoObj.profileId === "aaa000001") {
          const newPage = this.$router.resolve({
            path: "/setuppage/Administration",
            query: {
              isGoDetail: true,
              goDetailId: item.value,
            },
          });
          window.open(newPage.href, "_blank");
        } else {
          // 抱歉，您无权查看该条数据，请联系系统管理员。
          this.$message.warning(
            this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
          );
        }
      }
      // 判断服务云下的工作订单、工作订单行项目、工作类型详情下的服务报告模板根据简档权限跳转lightning设置
      if (
        (this.objectApi === "WorkOrder" ||
          this.objectApi === "WorkOrderLineItem" ||
          this.objectApi === "WorkType") &&
        item.apiname === "servicereporttemplateid"
      ) {
        if (this.$store.state.userInfoObj.profileId === "aaa000001") {
          let templateObj = item.data[0];
          const newPage = this.$router.resolve({
            path: "/setuppage/Administration",
            query: {
              type: "serviceReportTemplate",
              id: templateObj.id,
              label: templateObj.value,
            },
          });
          window.open(newPage.href, "_blank");
        } else {
          // 抱歉，您无权查看该条数据，请联系系统管理员。
          this.$message.warning(
            this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
          );
        }
      }
      if (
        item.apiname !== "profile" &&
        item.apiname !== "role" &&
        item.apiname !== "slaprocessid" &&
        item.apiname !== "servicereporttemplateid" &&
        item.data &&
        item.data[0]
      ) {
        // 阻止a标签默认跳转事件，防止点击后跳转两次()
        // item.apiname !== "slaprocessid" &&item.apiname !== "servicereporttemplateid"判断条件和hrefUrl中的判断相同，此时会走两次跳转，当前代码执行的前提是有item.data[0],所以存在走这里，并且阻止默认跳转，否则直接走hrefUrl方法跳转
        event.preventDefault();
        CommonObjApi.getPermissionById({ id: item.value }).then((res) => {
          if (res.result && res.returnCode === "1") {
            if (res.data.isQuery) {
              let path = this.consoleJump(item.data[0]);
              this.$router.push({
                path: path,
              });
            } else {
              // 抱歉，您无权查看该条数据，请联系系统管理员。
              this.$message.warning(
                this.$i18n.t("2018UI_Homepage_DataNoAccess_Info")
              );
            }
          }
        });
      }
    },
    // 选项框发生变化
    selectChange(val, item) {
      this.$emit("selectChange", arguments);
    },
    fileChange(params) {
      this.$emit("fileChange", params);
    },
    // 文件上传成功
    uploadFileSuccess(prop, fileId) {
      if (this.formData[prop] === undefined || this.formData[prop] === "") {
        this.$set(this.formData, prop, fileId);
      } else {
        this.$set(this.formData, prop, `${this.formData[prop]}${fileId}`);
      }
    },
    remove() {
      this.$emit("remove", ...arguments);
    },
    success() {},
    // 内部组件处理鼠标移出事件
    handleBlurInChild(item, val, formData, params) {
      // this.handleBlur && this.handleBlur(...arguments)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent =
        item.changeEvent !== undefined ? item.changeEvent.split(",") : [];
      changeEvent.forEach((eventItem) => {
        if (eventItem === "control") {
          eventItem && this.$emit(eventItem, val, item.fieldId, this.formData);
        } else {
          eventItem && this.$emit(eventItem);
        }
      });
    },
    // 修改记录类型
    changeRecordtype(type) {
      this.$emit("changeRecordtype", type);
    },
    // 值发生改变时候执行的事件
    changeEvent(callback, selectVal, selectItem) {
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent = callback !== undefined ? callback.split(",") : [];
      if (selectItem.prop !== "recordtype") {
        changeEvent.forEach((eventItem) => {
          if (eventItem === "control") {
            eventItem &&
              this.$emit(
                eventItem,
                selectVal,
                selectItem.fieldId,
                this.formData
              );
          } else {
            eventItem &&
              this.$emit(eventItem, selectVal, selectItem, this.formData);
          }
        });
      }
    },
    // 获取焦点时触发事件
    focusEvent() {},
    // 富文本内容变更
    onContentChange(callback, content, selectItem) {
      this.formData[selectItem.prop] = content;
    },
    selecter(cb, data) {
      cb(data);
    },
    // 下拉选项框出现时候触发
    visibleChange(flag) {
      // 如果显示  获取所有下拉选项框 并改变他们的最大宽度
      if (flag) {
        let elSelectDropdown =
          document.getElementsByClassName("el-select-dropdown");
        for (let i = 0; i < elSelectDropdown.length; i++) {
          let el = elSelectDropdown[i];
          el.style.maxWidth = el.style.minWidth;
        }
      }
    },
    // 修改某个属性的键值
    changeProps(props, key, val) {
      for (let i = 0; i < this.formAttr.length; i++) {
        let item = this.formAttr[i];
        if (item.prop === props) {
          item[key] = val;
          break;
        }
      }
    },
    /**
     *  这个是 乎福军 让加的，有经纬度参数则展示图标，编辑页不管
     */
    isShowLocationIconFn(item) {
      if (item?.children[item?.children?.length - 1]?.value) {
        return true;
      } else {
        return false;
      }
    },
    // 跳转地图
    mapBtn(item) {
      /**
       * 如果不是有效的 经纬度地址，则不打开地图
       * 张宏润提的 4772  BUG
       */

      if (!item?.children[item?.children?.length - 1]?.value) return;

      this.mapData = [];
      this.mapObjectData = {};
      this.bigMapDialog = true;
      let addressValue = item.value.split(" ");

      if (addressValue[0] === "中国" || addressValue[0] === "China") {
        item.children.forEach((val) => {
          if (!val?.value) return;
          if (val?.name?.includes("longitude")) {
            this.mapData[0] = val.value;
          } else if (val?.name?.includes("latitude")) {
            this.mapData[1] = val.value;
          }
        });
        this.isMap = true;
        this.mapFun();
      } else {
        if (this.$store.state.userInfoObj.language === "zh") {
          this.mapData = item.value.split(",").reverse();
          this.isMap = true;
          this.mapFun();
        } else {
          item.children.forEach((val) => {
            if (item.fieldType === "LT") {
              if (val.name && val.name === "jingweidula") {
                this.$set(this.mapObjectData, "lat", Number(val.value));
              } else if (val.name && val.name === "jingweiduco") {
                this.$set(this.mapObjectData, "lng", Number(val.value));
              }
            } else {
              if (val.name && val.name.slice(-9) === "longitude") {
                this.$set(this.mapObjectData, "lat", Number(val.value));
              } else if (val.name && val.name.slice(-8) === "latitude") {
                this.$set(this.mapObjectData, "lng", Number(val.value));
              }
            }
          });
          this.isMap = false;
          this.mapFun();
        }
      }
    },
    //地图初始化
    mapFun() {
      if (this.isMap) {
        this.mapkey = window.Glod["AMAP_KEY"];
      } else {
        this.mapkey = "AIzaSyCyWXGrhpOd1py9eUCpzRAzbDjWtXQNqGU";
      }
      this.$nextTick(() => {
        this.$refs.mapGroup.init();
      });
    },
    //关闭地图
    bigClose() {
      this.bigMapDialog = false;
    },
    //天眼查弹框
    tianyan() {
      this.$refs.tian.names(this.formData.name);
      this.$refs.tian.handleCloses();
    },
    //回填客户名
    setTianyanValue(val) {
      this.$emit("showEditableCell", val, "name"); // 进编辑状态
    },
    //回填全部数据
    setTianyanValuees(val) {
      this.$emit("showEditableCell", val, "all"); // 进编辑状态
    },
    //层级结构跳转
    routers() {
      this.$parent.$parent.$parent.routers();
    },
    // 获取当前用户信息
    async getUserInfo() {
      let res = await CommonObjApi.getUserInfo();
      if (res.result) {
        this.userInfo = res.data;
      }
    },
    //twitter,linkedin,facebook跳转
    twitterLinkedinFacebook(item) {
      let href = "http://" + item.value;
      window.open(href, "_blank");
    },
    //获取天眼查是否启用设置
    isOpenFun() {
      CommonObjApi.isOpen().then((res) => {
        this.isOpen = res.data.isOpen;
      });
    },
    //手机号 电话隐藏（功能暂时关闭，该方法不要删除）
    showPHdetail(val, type) {
      if (type == "phone") {
        this.showDetailPh = !this.showDetailPh;
        this.phoneDetail = val;
      }
      if (type == "mobile") {
        this.showDetailMb = !this.showDetailMb;
        this.phoneDetail1 = val;
      }
    },

    callPhone(phone) {
      this.$bus.$emit("newCLick", phone);
    },
    /**
     * 如果地址字段没有经纬度则不让点击，
     * 如果地址字段有经纬度则让点击
     */
    itemComputed(item) {
      let resarr = [];
      item?.children?.forEach((itemx) => {
        if (itemx.name.includes("latitude") && !!itemx.value) {
          resarr.push(itemx.value);
        } else if (itemx.name.includes("longitude") && !!itemx.value)
          resarr.push(itemx.value);
      });
      if (resarr.length == 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    ...mapState(["consoleData"]),
    /**
     * 优化dom判断到这里
     * || item.type === 'file' 图片字段不显示下划线
     */
    isShowLine() {
      return (item) => {
        let hideSelectionId = this.hideSelectionId;
        return !(
          (item.type === "groupTitle" && !item.hidden) ||
          ((item.type === "noData" || item.hidden) &&
            hideSelectionId.indexOf(item.selectionId) === -1) ||
          item.type === "customPage"
        )
          ? hideSelectionId.indexOf(item.selectionId) === -1
            ? "flex-item"
            : "flex-item-closed"
          : item.type === "noData"
          ? "no-border-flex-item"
          : "";
      };
    },
  },
  watch: {
    formAttr: {
      handler: function () {
        if (this.autoRefresh) {
          this.filterFormData();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
// 表单flex布局
.form-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
// 无数据占位
.no-border-flex-item {
  margin: 0 0 8px 10px;
  padding-left: 10px;
}
// 表单字段下划线位置修改
.flex-item {
  margin: 0 0 8px 10px;
  padding-left: 10px;
  border-bottom: 0.5px solid #dedcda;
}
// 表单字段下划线位置修改
.noDataLine {
  height: 57px;
  margin-bottom: 0;
}

// 评分字段显示
.stars {
  list-style: none;
  margin: 0;
  word-break: break-all;
  line-height: 36px;
  color: #ccc;
  float: left;
}

.stars span {
  font-size: 23px;
  margin-left: 4px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

// 标签样式
.tag {
  font-size: 12px;
  padding: 5px;
  height: 25px;
  line-height: 15px;
  margin-right: 5px;
}

// 查找字段文字超出换行
::v-deep .jumpDetailP span {
  display: inline !important;
  white-space: normal !important;
}

.helpImg {
  width: 14px;
  margin-left: 5px;
  margin-top: -3px;
}

// facebook、linkedin、twitter图标
.linkedinImg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  display: inline-block;
}

// 进度条样式修改
::v-deep .el-progress-bar {
  padding-right: 14px;
}

::v-deep .el-tag--dark {
  background-color: #62a5f3;
  border-color: #62a5f3;
}

::v-deep .el-tag--dark.el-tag--info {
  background-color: #c8c6c6;
  border-color: #c8c6c6;
}

::v-deep .el-tag--dark.el-tag--danger {
  background-color: #f6817b;
  border-color: #f6817b;
}

::v-deep .el-tag--dark.el-tag--success {
  background-color: #7cedcc;
  border-color: #7cedcc;
}

::v-deep .el-tag--dark.el-tag--warning {
  background-color: #fec366;
  border-color: #fec366;
}

.rotate90 {
  transform: rotate(-90deg);
}

.allow-click {
  color: #006dcc;
  text-decoration: solid;
  word-break: break-word;
}

.remoteBtn {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.Unallow-click {
  color: black;
  text-decoration: solid;
  cursor: auto !important;
}

::v-deep .el-form-item {
  // margin-bottom: 13px;
  margin-bottom: 0;
}

::v-deep .el-form-item__content {
  font-size: 14px;
  min-height: 32px;
  line-height: 36px !important;
  color: #080707;
}

::v-deep .el-input__icon {
  height: 28px;
  line-height: 28px;
}

::v-deep .el-select__tags {
  margin-top: 5px;
}

::v-deep .el-form-item__label {
  word-break: break-word;

  .itemBox {
    height: 62px;
  }
}
// 文本字段展示样式
.infoItemText {
  color: #080707;
  min-height: 28px;
  display: flex;
  line-height: 28px;
}
.infoItem {
  color: #080707;
  min-height: 28px;
  display: block;
  line-height: 28px;
  word-break: break-word;
}

.editIcon2 {
  width: 12px;
  position: absolute;
  top: 13px;
  right: 6px;
  z-index: 100;
}

.editIcon {
  width: 12px;
  cursor: pointer;
  position: absolute;
  top: 13px;
  right: 20px;
  z-index: 100;

  &:hover {
    color: #006dcc;
  }
}

.confirmIcon {
  cursor: pointer;
  border: 1px solid #dedcda;
  border-radius: 50%;
  width: 16px;
  left: 6px;
  position: relative;
  top: 2px;
  height: 16px;
  line-height: 16px;
  font-size: 14px;
}

.groupTitle {
  font-weight: bold;
  line-height: 40px;
  font-size: 14px;
  padding-left: 10px;
  border-radius: 3px;
  margin-top: 8px;
  background: #f3f2f2;
  margin-bottom: 0;
  color: #080707;
  cursor: pointer;
  display: flex;
  align-items: center;
  span {
    display: flex;
    ::v-deep font {
      display: flex;
      marquee {
        height: 100%;
      }
    }
  }

  &:hover {
    background: #eef1f6;
  }

  > i {
    font-size: 14px;
    color: #000;
    margin-right: 8px;
  }
}

::v-deep .el-tabs__nav {
  padding: 0 8px;
}

::v-deep .el-form--label-top .el-form-item__label {
  line-height: 24px;
}

::v-deep .el-form-item__label {
  padding: 0 12px 0 0 !important;
  font-size: 12px;
  color: #3e3e3c;
}

.el-input,
.el-select,
.el-textarea {
  width: calc(100% - 78px);
}

.el-input,
::v-deep .el-input__inner {
  height: 28px;
  line-height: 28px;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: calc(100% - 78px);
}

.el-input-number {
  width: calc(100% - 78px);

  ::v-deep .el-input__inner {
    text-align: left;
  }
}
::v-deep .big-view-box {
  .el-dialog__header {
    padding: 0 !important;
    .el-dialog__headerbtn {
      display: none;
    }
  }
  .el-dialog__body {
    padding: 0 !important;
    .bm-view-big {
      width: 100%;
      height: 550px;
    }
    .btn_map {
      position: absolute;
      top: 16px;
      right: 90px;
      z-index: 9;
      button {
        height: 44px;
      }
    }
  }
  .clearfix {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.pic_item_style {
  margin-bottom: 5px;
}

// 是否是经纬度杨氏
.is_location_style {
  color: #006dcc;
  cursor: pointer;
}

// 地址行显示的的样式
.address_info_style {
  display: flex;
  align-items: center;
  .address_icon {
    width: 18px;
    height: 18px;
    margin-left: 20px;
    flex-shrink: 0;
  }
}
</style>
