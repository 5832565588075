<template>
  <!-- 电话录音播放器 -->
  <div class="music_box">
    <audio
      ref="audio"
      :src="src"
      @pause="onPause"
      @play="onplay"
      @timeupdate="onTimeupdate"
      @loadedmetadata="onLoadedmetadata"
    ></audio>
    <!-- 播放控件 -->
    <div>
      <span class="play-btn" @click="startPlayOrPause">
        <svg
          class="playIcon"
          aria-hidden="true"
          slot="reference"
          v-show="audio.playing == false"
        >
          <use href="#icon-play"></use>
        </svg>
        <svg
          class="playIcon"
          aria-hidden="true"
          slot="reference"
          v-show="audio.playing == true"
        >
          <use href="#icon-stop"></use>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import * as callCenterApi from "../api.js"; // 呼叫中心接口
// 将整数转换成 时：分：秒的格式
function realFormatSecond(second) {
  var secondType = typeof second;
  if (secondType === "number" || secondType === "string") {
    second = parseInt(second);
    var hours = Math.floor(second / 3600);
    second = second - hours * 3600;
    var mimute = Math.floor(second / 60);
    second = second - mimute * 60;
    return (
      hours + ":" + ("0" + mimute).slice(-2) + ":" + ("0" + second).slice(-2)
    );
  } else {
    return "0:00:00";
  }
}

export default {
  props: {
    voiceRecord: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sliderTime: 0,
      audio: {
        maxTime: 0 /* 音频最大播放时长 */,
        currentTime: 0 /* 当前播放时长 */,
        playing: false /* 音频当前处于播放/暂停状态 */,
      },
      src: "",
      language: "",
      countryBool: true,
    };
  },

  filters: {
    /* 整数转换时分秒 */
    formartSecond(second = 0) {
      return realFormatSecond(second);
    },
  },
  mounted() {
    if (this.voiceRecord.substr(0, 6) == "medias") {
      let params = {
        mainUniqueId: this.voiceRecord,
      };
      callCenterApi.getCdr(params).then((res) => {
        if (res.result && res.returnCode == "200") {
          this.src = res.data;
        }
      });
    } else {
      let paramsaws = {
        voicerecording: this.voiceRecord,
      };
      callCenterApi
        .getConnectCallRecording(paramsaws)
        .then((res) => {
          if (res.data.code == 200) {
            this.src = res.data.url;
          }
        })
        .catch(() => {});
    }
  },

  methods: {
    /* 进度条格式化toolTip */
    formatProcessToolTip(index = 0) {
      index = parseInt((this.audio.maxTime / 100) * index);
      return (
        this.$i18n.t("front-kefu-moudle-time-length") +
        ":" +
        +realFormatSecond(index)
      );
    },

    /* 拖动进度条，改变当前时间 index是进度条改变时的回调函数的参数 值为0~100之间，需要换算成实际时间 */
    changeCurrentTime(index) {
      this.$refs.audio.currentTime = parseInt(
        (index / 100) * this.audio.maxTime
      );
    },

    /* 音频加载完成后的回调函数 */
    onLoadedmetadata(res) {
      this.audio.maxTime = parseInt(res.target.duration);
    },

    /* 每秒触发一次 用来更新当前播放时间 */
    onTimeupdate(res) {
      this.audio.currentTime = res.target.currentTime;
      /* 当音频播放时 进度条也要随之改变 */
      this.sliderTime = parseInt(
        (this.audio.currentTime / this.audio.maxTime) * 100
      );
    },

    /* 控制音频播放、暂停 */
    startPlayOrPause() {
      this.audio.playing ? this.pause() : this.play();
    },

    /* 播放音频 */
    play() {
      if (this.voiceRecord.substr(0, 6) == "medias") {
        let params = {
          mainUniqueId: this.voiceRecord,
        };
        callCenterApi.getCdr(params).then((res) => {
          if (res.result && res.returnCode == "200") {
            this.$refs.audio.play();
          } else if (res.returnCode == "300") {
            this.$message.warning(
              this.$i18n.t("front-kefu-moudle-hqyyjlsb-admin")
            );
          }
        });
      } else {
        let paramsaws = {
          voicerecording: this.voiceRecord,
        };
        callCenterApi
          .getConnectCallRecording(paramsaws)
          .then((res) => {
            if (res.data.code == 200) {
              this.$refs.audio.play();
            } else if (res.data.code == 402) {
              this.$message.warning(
                this.$i18n.t("front-kefu-moudle-myzdhsc-file")
              );
            } else if (res.data.code == 401) {
              this.$message.warning(
                this.$i18n.t("front-kefu-moudle-nofind-instance")
              );
            }
          })
          .catch(() => {});
      }
    },

    /* 暂停音频 */
    pause() {
      this.$refs.audio.pause();
    },

    /* 当音频播放 */
    onplay() {
      this.audio.playing = true;
    },

    /* 当音频暂停 */
    onPause() {
      this.audio.playing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.music_box {
  display: inline-block;
  vertical-align: sub;
  .player-box {
    display: flex;
    align-items: center;
    background: #f4f5f5;
    border-radius: 20px;
    padding: 0 25px;
  }
  .play-btn {
    cursor: pointer;
    font-size: 25px;
    display: flex;
    .playIcon {
      width: 33px;
      height: 20px;
    }
  }
  .slider {
    width: calc(100% - 170px);
    margin-left: 20px;
  }
}
</style>